@tailwind base;
@tailwind components;
@tailwind utilities;


.light {
  --primary: #e07643;
  --primary-1: #282828;
  --primary-2: #ffffff;
  --muted: #7a7874;
  --muted-0: #e076431a;
  --muted-1: #7d7874;
  --muted-2: #a3a2a9;
  --muted-3: #e4e4e4;
  --muted-3-rgb: 228, 228, 228;
  --muted-4: #282828;
  --muted-5: #f6f6f6;
  --muted-6: #fafafa;
  --muted-7: #d9d9d9;
  --muted-8: #f2f1ef;
  --muted-9: #f5f3f0;
  --muted-10: #514e49;
  --muted-11: #f0f0f0;
  --muted-12: #fafafa;
  --hoverSB: #ededed;
  --white: #ffffff;
  --dark: #282828;
}

.dark {
  --primary: #e07643;
  --primary-1: #e4e4e4;
  --primary-2: #2d2d2d;
  --muted: #000000;
  --muted-0: #e076431a;
  --muted-1: #c1c1c1;
  --muted-2: #a3a2a9;
  --muted-3: #4e4e4e;
  --muted-3-rgb: 78, 78, 78;
  --muted-4: #4e4e4e;
  --muted-5: #1d1d1d;
  --muted-6: #2d2d2d;
  --muted-7: #2d2d2d;
  --muted-8: #1e1e1e;
  --muted-9: #242424;
  --muted-10: #808080;
  --muted-11: #2d2d2d;
  --muted-12: #242424;
  --hoverSB: #2d2d2d;
  --white: #ffffff;
  --dark: #282828;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-13cymwt-control {
  min-height: 42px !important;
  max-height: 42px !important;
  min-width: 200px !important;
  border-width: 1 !important;
  border-color: '#e4e4e4' !important;
  border-radius: 8px !important;
}

.css-1nmdiq5-menu {
  z-index: 9999;
  min-width: fit-content
}

.css-t3ipsp-control {
  min-width: 200px !important;
}

.select_menu {
  z-index: 999;
}

.react-datepicker-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
  height: 100% !important;
}

.react-datepicker__input-container {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  justify-items: center !important;
}

input {
  width: 100% !important;
  line-height: initial !important;
}

input:focus {
  display: flex !important;
  /* justify-items: center !important; */
  border: 0px !important;
  outline: none;
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(4px);
  z-index: 40;
}
